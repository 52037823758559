<template>
  <div>
    <detail-head :prj-oid="prjOid" :cat-name="currentCat.cat_name" :columns="columns" :data="data"></detail-head>
    <div class="content">
      <div class="card">
        <div class="title">
          图表
          <div class="right">
          </div>
        </div>
        <echarts style="height: 400px;width: 100%;" v-if="options" :option="options"></echarts>
      </div>
      <div class="card">
        <div class="title">数据详情</div>
        <a-table rowKey="0" :pagination="false" :data-source="data" :columns="columns"></a-table>
      </div>
    </div>
  </div>
</template>

<script>
import DetailHead from "@/view/project/report/detailHead.vue";
import Echarts from "@/components/echarts.vue";
import {fetch} from "@/utils/request";
// import dayjs from "dayjs";
import lodash from "lodash"

export default {
  name: "worktimeChartsReport",
  components: {Echarts, DetailHead},
  props: {
    currentCat: Object,
    prjOid: String,
    phaseList: Array
  },
  data() {
    return {
      columns: [],
      data: [],
      delayDays: ['1-3天', '3-7天', '7-15天', '15-30天', '>30天'],
      options: false,
      raw: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleRaw() {
      if (this.currentCat.cat_code === '14') {
        this.columns = [
          {title: "", dataIndex: "0"}
        ]
        this.data.push({0: "合计登记工时"})
        this.data.push({0: "合计预估工时"})
        for (let item of this.raw) {
          let phase_oid = ""
          for (let value of item.values) {
            phase_oid = value.phase_oid
            if (!this.columns.find(column => column.dataIndex === value.phase_oid)) {
              this.columns.push({
                title: value.phase_name,
                dataIndex: value.phase_oid
              })
            }
            let index = this.data.findIndex(findItem => findItem.username === value.username)
            if (index === -1){
              this.data.push({
                0: value.title ? value.username + "-" + value.title : value.username,
                username: value.username,
                [value.phase_oid]: 0
              })
              index = this.data.length - 1
            }
            this.data[index][value.phase_oid] = lodash.round((this.data[index][value.phase_oid] ?? 0) + value.worktime_hour, 2)
          }
          this.data[0][phase_oid] = lodash.round((this.data[0][phase_oid] ?? 0) + item.sum_hour, 2)
          this.data[1][phase_oid] = lodash.round((this.data[1][phase_oid] ?? 0) + item.sum_schedule, 2)
        }
        this.data.slice(2).forEach((dataItem, dataIndex) => {
          this.columns.slice(1).forEach(column => {
            console.log(dataIndex, column.dataIndex, dataItem[column.dataIndex])
            if (dataItem[column.dataIndex] === undefined) {
              this.$set(this.data[dataIndex + 2], column.dataIndex, 0)
            }
          })
        })
        this.options = {
          legend: {
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              let result="";
              for (let i = 0; i < params.length; i++) {
                if(params[i].data!==0){
                  result+=params[i].marker + params[i].seriesName + '：' + params[i].data + '<br>'
                }
              }
              return params[0].name + '<br/>'
                  + result
            }
          },
          xAxis: {
            type: 'category',
            data: this.data.slice(2).map(item => item[0])
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '50px',
            bottom: '30px',
            right: "30px",
          },
          series: this.columns.slice(1).map((column) => {
            return {
              type: "bar",
              barMaxWidth: "100px",
              name: column.title,
              stack: "1",
              data: this.data.slice(2).map(item => {
                return item[column.dataIndex] ?? 0
              })
            }
          })
        }
      }
      if (['15', '16', '17'].includes(this.currentCat.cat_code)) {
        this.columns = [
          {title: "", dataIndex: "0"},
        ]
        this.data = [
          {"0": "合计登记工时"}
        ]
        for (let item of this.raw) {
          let row = {
            "0": item.key
          }
          for (let value of item.values) {
            if (!this.columns.find(item => item.dataIndex === value.username)) {
              this.columns.push({
                dataIndex: value.username,
                title: value.title ? value.username + "-" + value.title : value.title
              })
            }
            this.data[0][value.username] = lodash.round((this.data[0][value.username] ?? 0) + value.worktime_hour, 2)
            let dataIndex = this.data.find(data => data['0'] === value.username)
            if (dataIndex === -1) {
              this.data.push({
                "0": item.key
              })
            }
            row[value.username] = lodash.round((row[value.username] ?? 0) + value.worktime_hour, 2)
          }
          this.data.push(row)
        }
        this.data.forEach((dataItem, dataIndex) => {
          this.columns.slice(1).forEach(column => {
            if (dataItem[column.dataIndex] === undefined) {
              this.$set(this.data[dataIndex], column.dataIndex, 0)
            }
          })
        })
        this.options = {
          legend: {
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              let result="";
              for (let i = 0; i < params.length; i++) {
                if(params[i].data!==0){
                  result+=params[i].marker + params[i].seriesName + '：' + params[i].data + '<br>'
                }
              }
              return params[0].name + '<br/>'
                  + result
            }
          },
          xAxis: {
            type: 'category',
            data: this.data.slice(1).map(item => item['0'])
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '50px',
            bottom: '30px',
            right: "30px",
          },
          series: this.columns.slice(1).map(column => {
            return {
              type: "bar",
              barMaxWidth: "100px",
              name: column.title,
              stack: "1",
              data: this.data.slice(1).map(item => {
                return item[column.dataIndex] ?? 0
              })
            }
          })
        }
      }
    },
    getData() {
      let sta_type = {
        14: "2",
        15: "3",
        16: "5",
        17: "4"
      }[this.currentCat.cat_code]
      fetch("post", "/prj/worktimelog/sta", {
        sta_type,
        prjoid: this.prjOid
      }).then(res => {
        this.raw = res.data
        this.handleRaw()
        // if (res.status === 0) {
        //   if (["15", "16"].includes(this.currentCat.cat_code)) {
        //     let formatString = {
        //       15: "YYYY-MM-DD",
        //       16: "YYYY-M"
        //     }[this.currentCat.cat_code]
        //     this.raw = res.data.sort((a, b) => {
        //       let aNum = dayjs(a.key, formatString).valueOf()
        //       let bNum = dayjs(b.key, formatString).valueOf()
        //       console.log(dayjs(a.key, formatString))
        //       if (aNum < bNum) {
        //         return 1
        //       } else if (aNum > bNum) {
        //         return 1
        //       } else {
        //         return 0
        //       }
        //     })
        //   }
        //   this.handleRaw()
        // }
      })
    }
  }
}
</script>