<template>
  <div>
    <detail-head :prj-oid="prjOid" :cat-name="currentCat.cat_name" :columns="columns" :data="data"></detail-head>
    <div class="content">
      <div class="card" v-if="['14', '15', '16', '17'].includes(this.currentCat.cat_code)">
        <div class="title">
          图表
        </div>
        <echarts style="height: 400px;width: 100%;" v-if="options" :option="options"></echarts>
      </div>
      <div class="card">
        <div class="head d-flex j-c-space-b">
          <div class="title">数据详情</div>
          <div class="right-info">
            <div>总计 {{ sum_hour }} 登记小时</div>
            <span class="line"></span>
            <div v-if="currentCat.cat_code === '19'">总计 {{ sum_schedule }} 预估小时</div>
            <span v-if="currentCat.cat_code === '19'" class="line"></span>
            <div>总计 {{ sum_length }} 条记录</div>
          </div>
        </div>
        <a-table :pagination="false" :data-source="data" :columns="columns" row-key="oid">
            <template slot="username" slot-scope="_, row">
              <div class="edp-owners">
                <div>
                  <a-avatar v-if="row.pic" :size="16" :src="row.pic" />
                  <span>{{ row.title ? row.username + '-' + row.title : row.username }}</span>
                </div>
              </div>
            </template>
            <template slot="worktime_hour" slot-scope="_, row">
              {{ row.worktime_hour ? `${row.worktime_hour}小时` : `` }}
            </template>
            <template slot="start_time" slot-scope="_, row">
              {{ row.start_time }}
            </template>
            <template slot="prj_name" slot-scope="_, row">
              {{ row.prj_name }}
            </template>
            <template slot="issue_name" slot-scope="_, row">
              {{ row.issue_name }}
            </template>
            <template slot="status" slot-scope="_, row">
              <div v-if="row.status"
                  class="edp-status"
                  :class="{
                    blue: row.status === '未开始',
                    yellow: row.status === '进行中',
                    green: row.status === '已完成',
                    gray: row.status === '已归档',
                  }">
                {{ row.status }}
              </div>
            </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import DetailHead from "@/view/project/report/detailHead.vue";
import Echarts from "@/components/echarts.vue";
import {fetch} from "@/utils/request";
import {genGuid} from "@/utils/helpFunc";
import lodash from "lodash";
export default {
  name: "workTimeReport",
  components: { DetailHead, Echarts },
  props: {
    currentCat: Object,
    prjOid: String,
    phaseList: Array
  },
  data() {
    return {
      raw: [],
      data: [],
      columns: [],
      delayDate: "",
      options: false,
      delayDays: ['1-3天', '3-7天', '7-15天', '15-30天', '>30天'],
      map: {
        14: "2",// 登记人-迭代工时总览
        2123: "15",// 登记人-每天工时总览
        3123: "16",// 登记人每月工时总览
        4123: "17",// 登记人每周工时总览
        18: "1",// 成员工时日志报表
        19: "2", // 迭代工时日志报表
        20: "3", // 每日工时日志报表
        21: "5", // 每月工时日志报表
        22: "4", // 每周工时日志报表
      },
      treeDelArr: [],
      sum_hour: '',
      sum_schedule: '',
      sum_length: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // object key:cat_code value: 接口需要的report_flag
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/worktimelog/sta" : "/prj/worktimelog/sta"
      fetch("post", url, {
        prjoid: this.prjOid,
        begin_time: "",
        end_time: "",
        sta_type: this.map[this.currentCat.cat_code]
      }).then(res => {
        if (res.status === 0 || window.mock) {
          this.raw = res.data
          this.handleRaw()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleRaw() {
      if (['19','18', '20', '21', '22'].includes(this.currentCat.cat_code)) {
        this.columns = [
          {title: "提交人", dataIndex: "username", scopedSlots: { customRender: 'username'}},
          {title: "登记工时", dataIndex: "worktime_hour", width: '140px', scopedSlots: { customRender: 'worktime_hour'},
            // eslint-disable-next-line no-unused-vars
            customRender: (value, row, index) => {
              const obj = {
                children:
                      <div>
                        {
                          !row.values ? <div>{row.worktime_hour ? `${row.worktime_hour}小时` : ``}</div> :
                              <div class="col-parent">
                                <div>合计登记 {row.sum_hour} 小时</div><span class="line"></span>
                                {
                                  this.currentCat.cat_code === '19' ? <div class="d-flex"><div>合计预估 {row.sum_schedule} 小时</div><span class="line"></span></div> : ''
                                }
                                <div>合计 {row.count} 记录</div>
                              </div>
                        }
                      </div>,
                attrs: {},
              };
              if (row.values) {
                obj.attrs.colSpan = 5;
              }
              return obj;
            }
          },
          {
            title: "开始时间", dataIndex: "start_time", width: '180px', scopedSlots: { customRender: 'start_time'},
            // eslint-disable-next-line no-unused-vars
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.values) {
                obj.attrs.colSpan = 0
              }
              return obj;
            }
          },
          {
            title: "所属项目", dataIndex: "prj_name",  scopedSlots: { customRender: 'prj_name'},
            // eslint-disable-next-line no-unused-vars
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.values) {
                obj.attrs.colSpan = 0
              }
              return obj;
            }
          },
          {
            title: "工作项名称", dataIndex: "issue_name",  scopedSlots: { customRender: 'issue_name'},
            // eslint-disable-next-line no-unused-vars
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.values) {
                obj.attrs.colSpan = 0
              }
              return obj;
            }
          },
          {
            title: "状态", dataIndex: "status", width: '100px', scopedSlots: { customRender: 'status'},
            // eslint-disable-next-line no-unused-vars
            customRender: (value, row, index) => {
              const obj = {
                children: <div
                          class={{'edp-status': true, blue: row.status === '未开始',yellow: row.status === '进行中',green: row.status === '已完成',gray: row.status === '已归档',}}>
                          {row.status}
                          </div>,
                attrs: {},
              };
              if (row.values) {
                obj.attrs.colSpan = 0
              }
              return obj;
            }
          },
        ]

        let data = this.raw.map(e => {
          return {
            ...e,
            username: e.key,
            oid: genGuid(10),
            values: e.values.map(a => {
              return{
                ...a,
                oid: genGuid(10)
              }
            })
          }
        })


        console.log(data);
        console.log(this.createBaseTreeData(data, 'values'));
        this.treeDelArr = []
        this.data = this.createBaseTreeData(data, 'values')

        this.sum_hour = lodash.ceil(lodash.sumBy(data, 'sum_hour'), 2)
        this.sum_schedule = lodash.sumBy(data, 'sum_schedule')
        this.sum_length = this.data.length - data.length
      }
    },
    createBaseTreeData(treeArr, childrenList) { // 数组打平
      let cloneData = JSON.parse(JSON.stringify(treeArr));
      cloneData.map(item => {
        this.treeDelArr = this.treeDelArr.concat(item);
        if (item[childrenList]) this.createBaseTreeData(item[childrenList], childrenList);
      })
      return this.treeDelArr;
    },
  }
}
</script>

<style scoped lang="less">
.app-main-content{
  .content{
    .card{
      .head{
        padding: 20px 0 15px;

        .title{
          margin: 0;
        }

        .right-info{
          display: flex;

          >.line{
            line-height: 27px;
            height: 27px;
            width: 1px;
            background-color: #CACACA;
            margin: 0 15px;
          }

          >div{
            line-height: 27px;
          }
        }
      }
    }
  }
}

  .col-parent{
    display: flex;
    align-items: center;
    justify-content: right;

    .line{
      line-height: 20px;
      height: 20px;
      width: 1px;
      background-color: #CACACA;
      margin: 0 15px;
    }

    div{
      line-height: 20px;
    }
  }
</style>
