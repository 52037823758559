<template>
  <div class="app-main-content">
    <task-report v-if="currentTemp10 === 'R_TASK'" :prj-oid="prjoid" :current-cat="currentCat" :phase-list="phase_list"></task-report>
    <product-report v-if="currentTemp10 === 'R_PRODUCT'" :prj-oid="prjoid" :current-cat="currentCat" :phase-list="phase_list"></product-report>
    <money-log-report v-if="currentTemp10 === 'R_FINANCE'" :prj-oid="prjoid" :current-cat="currentCat" :phase-list="phase_list"></money-log-report>
    <worktime-charts-report v-if="['14', '15', '16', '17'].includes(currentCat.cat_code)" :prj-oid="prjoid" :current-cat="currentCat" :phase-list="phase_list"></worktime-charts-report>
    <work-time-report v-else-if="currentTemp10 === 'R_TIMESHEET'" :prj-oid="prjoid" :current-cat="currentCat" :phase-list="phase_list"></work-time-report>
  </div>
</template>

<script>
import catMixin from "@/utils/mixins/catMixin.js";
import TaskReport from "@/view/project/report/taskReport.vue";
import {fetch} from "@/utils/request.js";
import ProductReport from "@/view/project/report/productReport.vue";
import MoneyLogReport from "@/view/project/report/moneyLogReport.vue";
import workTimeReport from "@/view/project/report/workTimeReport.vue";
import WorktimeChartsReport from "@/view/project/report/worktimeChartsReport.vue";

export default {
  components: {WorktimeChartsReport, MoneyLogReport, ProductReport, TaskReport, workTimeReport},
  mixins: [catMixin],
  data() {
    return {
      currentCat: {},
      report_menu: [],
      prjoid: "",
      phase_list: [],
      relation: [
        {temp10: 'R_TASK', codes: ['1', '2', '3', '4', '5', '6', '7']},
        {temp10: 'R_TIMESHEET', codes: ['14','15','16','17','18','19','20','21','22']},
        {temp10: 'R_FINANCE', codes: ['10', '11', '12']},
        {temp10: 'R_PRODUCT', codes: ['8', '9']},
      ]
    }
  },
  computed: {
    currentTemp10() {
      if (this.currentCat?.cat_code) {
        let find = this.relation.find(item => {
          return item.codes.includes(this.currentCat.cat_code)
        })
        if (find) {
          return find.temp10
        }
      }
      return ""
    }
  },
  async created() {
    this.prjoid = this.$route.query.prj_oid
    this.report_menu = await this.getOneCat("report_menu")
    this.currentCat = this.report_menu.find(item => {
      return item.cat_code === this.$route.query.cat_code
    })
    console.log(this.currentCat)
    fetch("post", "/prj/phase/retrieve/byprj", {
      prjoid: this.prjoid
    }).then(res => {
      if (res.status === 0) {
        this.phase_list = res.data
      }
    })
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  display: flex;
  flex-direction: column;
  /deep/ .content {
    margin-top: 10px;
    background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
     .card {
       padding: 0 20px;
      .title {
        margin-top: 20px;
        color: #4D4D4D;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        .right {
          margin-left: auto;
          display: flex;
          .item {
            font-weight: 400;
            font-size: 14px;
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-right: 20px;
            }
            .label {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
