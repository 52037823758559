<template>
  <div>
    <detail-head :prj-oid="prjOid" :cat-name="currentCat.cat_name" :columns="columns" :data="data">
      <template v-if="currentCat.cat_code !== '10'" #right>
        <span></span>
      </template>
    </detail-head>
    <div class="content">
      <div class="card">
        <div class="title">
          图表
          <div class="right">
            <div class="item">
              <div class="label">阶段</div>
              <a-select @change="getData" v-model="phase_oid" style="width: 150px;">
                <a-select-option value="">全部阶段</a-select-option>
                <a-select-option v-for="item in phaseList" :key="item.oid" :value="item.oid">{{item.phase_name}}</a-select-option>
              </a-select>
            </div>
            <div class="item">
              <div class="label">标签</div>
              <a-select
                showArrow
                style="width: 210px;height: 32px;overflow: hidden;"
                :maxTagCount="1"
                class="sort-select"
                mode="multiple"
                @change="getData"
                v-model="money_tags">
                <a-select-option v-for="item in cat.money_tags" :key="item.cat_code" :value="item.cat_name">
                  {{item.cat_name}}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <echarts v-if="options" :option="options" width="100%" height="400px"></echarts>
      </div>
    </div>
    <div class="content" style="padding: 0 10px;">
      <a-tabs v-if="currentCat.cat_code !== '10'" default-active-key="1">
        <a-tab-pane key="1" tab="概览">
          <div class="card">
            <div class="num-list">
              <template v-if="currentCat.cat_code === '11'">
                <div class="num-item">
                  <div class="label">计划支出</div>
                  <div class="value red">CNY {{ sta.schedule_outcome | numberFormat }}</div>
<!--                  <div class="after">(CNY)</div>-->
                </div>
                <div class="num-item">
                  <div class="label">实际支出</div>
                  <div class="value red">CNY {{ sta.actual_outcome | numberFormat }}</div>
<!--                  <div class="after">(CNY)</div>-->
                </div>
              </template>
              <template v-else-if="currentCat.cat_code === '12'">
                <div class="num-item">
                  <div class="label">计划收入</div>
                  <div class="value green">{{ sta.schedule_income | numberFormat }}</div>
                </div>
                <div class="num-item">
                  <div class="label">实际收入</div>
                  <div class="value green">{{ sta.actual_income | numberFormat }}</div>
                </div>
              </template>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="明细列表">
          <a-table
            :pagination="false"
            style="padding-bottom: 10px"
            :data-source="moneyList"
            rowKey="oid"
          >
            <a-table-column title="ID" data-index="id" width="70px">
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="操作人">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <div style="display: flex;align-items: center">
                    <span class="money_status red" v-if="row.money_status === '0'">支</span>
                    <span class="money_status green" v-if="row.money_status === '1'">收</span>
                    <span class="name">
                      {{ row.reviser_title ? row.reviser_name + '-' + row.reviser_title : row.reviser_name }}
                    </span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务" data-index="issue_name"></a-table-column>
            <a-table-column title="财务标签">
              <template v-slot:default="_, row">
                <div v-if="row.money_tag">
                  <div class="edp-tag" v-for="(item, key) in row.money_tag.split(',')" :key="key" style="margin-right: 10px;">{{item}}</div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际金额">
              <template v-slot:default="_, row">
                <div>
                  {{row.money_type}}
                  {{row.amount | numberFormat}}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际时间">
              <template #default="_, row">
                {{row.business_date}}
              </template>
            </a-table-column>
            <a-table-column title="操作时间">
              <template #default="_, row">
                {{row.update_time}}
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
      </a-tabs>
      <a-tabs v-else-if="currentCat.cat_code === '10'">
        <a-tab-pane key="1" tab="数据详情">
          <div>
            <a-table style="padding-bottom: 10px" :pagination="false" :data-source="data" :columns="columns" :rowClassName="setRowClassName"></a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="计划收入">
          <a-table
            :pagination="false"
            style="padding-bottom: 10px"
            :data-source="planIncomeList"
            rowKey="seqid"
          >
            <a-table-column title="ID" data-index="id" width="120px">
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="任务负责人">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <div style="display: flex;align-items: center">
                    <span class="money_status green" >收</span>
                    <span class="name">{{ row.owners[0]?.title ? row.owners[0]?.name + "-" + row.owners[0]?.title : row.owners[0]?.title}}</span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务" data-index="issue_name"></a-table-column>
            <a-table-column title="计划收入">
              <template v-slot:default="_, row">
                <div>
                  CNY
                  {{row.schedule_income | numberFormat}}
                </div>
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="计划支出">
          <a-table
            :pagination="false"
            style="padding-bottom: 10px"
            :data-source="planPayoutList"
            rowKey="seqid"
          >
            <a-table-column title="ID" data-index="id" width="120px">
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="任务负责人">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <div style="display: flex;align-items: center">
                    <span class="money_status red">支</span>
                    <span class="name">{{ row.owners[0]?.title ? row.owners[0]?.name + "-" + row.owners[0]?.title : row.owners[0]?.title}}</span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务" data-index="issue_name"></a-table-column>
            <a-table-column title="计划支出">
              <template v-slot:default="_, row">
                <div>
                  CNY
                  {{row.schedule_outcome | numberFormat}}
                </div>
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" tab="实际收入">
          <a-table
            :pagination="false"
            style="padding-bottom: 10px"
            :data-source="realIncomeList"
            rowKey="oid"
          >
            <a-table-column title="ID" data-index="id" width="70px">
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="操作人">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <div style="display: flex;align-items: center">
                    <span class="money_status red" v-if="row.money_status === '0'">支</span>
                    <span class="money_status green" v-if="row.money_status === '1'">收</span>
                    <span class="name">
                      {{ row.reviser_title ? row.reviser_name + '-' + row.reviser_title : row.reviser_name }}
                    </span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务" data-index="issue_name"></a-table-column>
            <a-table-column title="财务标签">
              <template v-slot:default="_, row">
                <div v-if="row.money_tag">
                  <div class="edp-tag" v-for="(item, key) in row.money_tag.split(',')" :key="key" style="margin-right: 10px;">{{item}}</div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际金额">
              <template v-slot:default="_, row">
                <div>
                  {{row.money_type}}
                  {{row.amount | numberFormat}}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际时间">
              <template #default="_, row">
                {{row.business_date}}
              </template>
            </a-table-column>
            <a-table-column title="操作时间">
              <template #default="_, row">
                {{row.update_time}}
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="5" tab="实际支出">
          <a-table
            :pagination="false"
            style="padding-bottom: 10px"
            :data-source="realPayoutList"
            rowKey="oid"
          >
            <a-table-column title="ID" data-index="id" width="70px">
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="操作人">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <div style="display: flex;align-items: center">
                    <span class="money_status red" v-if="row.money_status === '0'">支</span>
                    <span class="money_status green" v-if="row.money_status === '1'">收</span>
                    <span class="name">
                      {{ row.reviser_title ? row.reviser_name + '-' + row.reviser_title : row.reviser_name }}
                    </span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务" data-index="issue_name"></a-table-column>
            <a-table-column title="财务标签">
              <template v-slot:default="_, row">
                <div v-if="row.money_tag">
                  <div class="edp-tag" v-for="(item, key) in row.money_tag.split(',')" :key="key" style="margin-right: 10px;">{{item}}</div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际金额">
              <template v-slot:default="_, row">
                <div>
                  {{row.money_type}}
                  {{row.amount | numberFormat}}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际时间">
              <template #default="_, row">
                {{row.business_date}}
              </template>
            </a-table-column>
            <a-table-column title="操作时间">
              <template #default="_, row">
                {{row.update_time}}
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import DetailHead from "@/view/project/report/detailHead.vue";
import Echarts from "@/components/echarts.vue";
import {fetch} from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";

export default {
  name: "moneyLogReport",
  components: {Echarts, DetailHead},
  mixins: [catMixin],
  props: {
    prjOid: String,
    currentCat: Object,
    phaseList: Array
  },
  data() {
    return {
      noCache: true,
      cat: {
        money_tags: [],
      },
      raw: [],
      options: false,
      columns: [],
      data: [],
      phase_oid: "",
      money_tags: [],
      sta: {
        budget_income: 0,
        budget_outcome: 0,
        schedule_income: 0,
        schedule_outcome: 0,
        actual_income: 0,
        actual_outcome: 0,
      },
      moneyList: [],
      realIncomeList: [],
      realPayoutList: [],
      planIncomeList: [],
      planPayoutList: []
    }
  },
  methods: {
    afterGetCat() {
      this.getData()
    },
    handleRaw() {
      if (this.currentCat.cat_code === '10') {
        let dataArr = []
        this.raw.forEach(rawItem => {
          let findIndex = dataArr.findIndex(i => i.date === rawItem.key)
          if (findIndex === -1) {
            dataArr.push({
              date: rawItem.key,
              in: rawItem.value.reduce((pre, cur) => {
                if (Number(cur.value) > 0) {
                  return pre += Number(cur.value)
                }
                return pre
              }, 0) + (dataArr.at(-1)?.in ?? 0),
              out: rawItem.value.reduce((pre, cur) => {
                if (Number(cur.value) < 0) {
                  return pre -= Number(cur.value)
                }
                return pre
              }, 0) + (dataArr.at(-1)?.out ?? 0)
            })
          }
        })
        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: "80px",
            top: '50px',
            bottom: '30px',
            right: "60px",
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: dataArr.map(item => item.date)
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '收入',
              type: 'line',
              stack: '1',
              itemStyle: {
                color: "#a0ea3f"
              },
              areaStyle: {
                opacity: 0.7
              },
              emphasis: {
                focus: 'series'
              },
              data: dataArr.map((item, index) => {
                return index === 0 || index === dataArr.length - 1 || item.in !== 0 ? item.in : null
              }),
              connectNulls: true
            },
            {
              name: '支出',
              type: 'line',
              stack: '2',
              itemStyle: {
                color: "#ff7579"
              },
              areaStyle: {
                opacity: 0.7
              },
              emphasis: {
                focus: 'series'
              },
              data: dataArr.map((item, index) => {
                return index === 0 || index === dataArr.length - 1 || item.out !== 0 ? item.out : null
              }),
              connectNulls: true
            },
          ]
        };
      }
      if (['11', '12'].includes(this.currentCat.cat_code)) {
        let tags = this.cat.money_tags.map(item => {
          return {
            name: item.cat_name,
            in: 0,
            out: 0
          }
        })
        this.raw.forEach(rawItem => {
          rawItem.value.forEach(item => {
              let number = Number(item.value)
              let tag_names = item.value_name.split(',')
              tag_names.forEach(tag_name => {
                if (tag_name !== "") {
                  let findIndex = tags.findIndex(tag => tag.name === tag_name)
                  if (findIndex === -1) {
                    tags.push({
                      name: tag_name,
                      in: 0,
                      out: 0
                    })
                    findIndex = tags.length - 1
                  }
                  if (number > 0) {
                    tags[findIndex].in += number
                  } else {
                    tags[findIndex].out -= number
                  }
                }
              })
          })
        })

        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: tags.map(item => item.name)
          },
          yAxis: {
            type: 'value',
          },
          grid: { // 让图表占满容器
            left: "60px",
            top: '30px',
            bottom: '30px',
            right: "60px",
          },
          series: [
            {
              type: "bar",
              barMaxWidth: "100px",
              name: {11: '支出', 12: '收入'}[this.currentCat.cat_code],
              data: tags.map(item => {
                return item[({11: 'out', 12: 'in'}[this.currentCat.cat_code])]
              }),
            },
          ]
        }
      }
    },
    async getTaskList() {
      return await fetch("post", "/prj/issue/get", {
        prjoid: this.prjOid
      }).then(res => {
        if (res.status === 0) {
          return res.data
        }
      })
    },
    getData() {
      let money_status = undefined
      if (this.currentCat.cat_code === '10') {
        this.getList("0").then(list => {
          this.realPayoutList = list
        })
        this.getList("1").then(list => {
          this.realIncomeList = list
        })
        this.getTaskList().then(list => {
          this.planIncomeList = list.filter(item => {
            return Number(item.schedule_income) > 0
          }).map(item => {
            item.children = undefined
            return item
          })

          this.planPayoutList = list.filter(item => {
            return Number(item.schedule_outcome) > 0
          }).map(item => {
            item.children = undefined
            return item
          })

        })
        fetch("post", "/prj/moneylog/sta", {
          prjoid: this.prjOid,
          phase_oid: this.phase_oid
        }).then(res => {
          if (res.status === 0) {
            this.columns = ['预算收入', '计划收入', '实际收入'].map(item => {
              return {
                title: item,
                dataIndex: item
              }
            })

            this.data = [
              {
                key: 0,
                "预算收入": res.data.budget_income,
                "计划收入": res.data.schedule_income,
                "实际收入": res.data.actual_income,
              },
              {
                key: 1,
                "预算收入": "预算支出",
                "计划收入": "计划支出",
                "实际收入": "实际支出",
              },
              {
                key: 2,
                "预算收入": res.data.budget_outcome,
                "计划收入": res.data.schedule_outcome,
                "实际收入": res.data.actual_outcome,
              }
            ]
          }
        })
      }
      if (this.currentCat.cat_code === '11') {
        money_status = '0'
      }
      if (this.currentCat.cat_code === '12') {
        money_status = '1'
      }
      if (['11', '12'].includes(this.currentCat.cat_code)) {
        fetch("post", "/prj/moneylog/sta", {
          prjoid: this.prjOid,
          phase_oid: this.phase_oid,
          money_tags: this.money_tags.join(",")
        }).then(res => {
          if (res.status === 0) {
            this.sta = res.data
          }
        })
        this.getList(money_status).then(res => {
          this.moneyList = res
        })
      }
      fetch("post", "/prj/moneylog/report", {
        prjoid: this.prjOid,
        money_status,
        phase_oid: this.phase_oid,
        money_tags: this.money_tags.join(",")
      }).then(res => {
        if (res.status === 0) {
          this.raw = res.data.datas
          this.handleRaw()
        } else {
          this.$message.error(res.message)
          this.raw = []
          this.handleRaw()
        }
      })
    },
    async getList(money_status) {
      return await fetch("post", "/prj/moneylog/retrieve", {
        prjoid: this.prjOid,
        phase_oid: this.phase_oid,
        money_tags: this.money_tags.join(","),
        page_size: 999,
        money_status
      }).then(res => {
        if (res.status === 0) {
          return res.data.datas
          //   .filter(item => {
          //   if (this.currentCat.cat_code === '11') {
          //     return item.money_status === '0'
          //   } else if (this.currentCat.cat_code === '12') {
          //     return item.money_status === '1'
          //   }
          // })
        } else {
          this.$message.error(res.message)
          return Promise.reject()
        }
      })
    },
    setRowClassName(record, index) {
      return index % 2 !== 0 ? 'odd-gray' : ''
    }
  }
}
</script>

<style lang="less">
.money_status {
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  display: inline-block;
  color: white;
  border-radius: 50%;
  margin-right: 5px;
  &.green {
    background-color: green;
  }
  &.red {
    background-color: red;
  }
}
.num-list {
  display: flex;
  padding-bottom: 20px;
  .num-item {
    height: 100px;
    display: flex;
    align-items: center;
    border-right: 1px solid #eee;
    margin-right: 40px;
    padding-right: 40px;
    padding-top: 30px;
    &:last-child {
      border-right: none;
    }
    .label,.after {
      font-size: 16px;
      color: #000000;
      margin-top: 2px;
    }
    .label {
      margin-right: 10px;
    }
    .after {
      margin-left: 10px;
    }
    .value {
      line-height: 1;
      font-weight: 700;
      font-size: 30px;
      &.green {
        color: green;
      }
      &.red {
        color: red;
      }
    }
  }
}
.odd-gray{
  background-color: #F8F8F8;
  &:hover > td{
    background-color: #F8F8F8!important;
  }
}

</style>