<template>
  <div>
    <detail-head :prj-oid="prjOid" :cat-name="currentCat.cat_name" :columns="columns" :data="data"></detail-head>
    <div class="content">
      <div class="card">
        <div class="title">
          图表
          <div class="right" v-if="$route.query.cat_code!=='9'">
            <div class="item">
              <div class="label">产品类别</div>
              <a-select
                showArrow
                style="width: 210px;height: 32px;overflow: hidden;"
                :maxTagCount="1"
                class="sort-select"
                mode="multiple"
                v-model="productTypes"
                @change="getData"
              >
                <a-select-option v-for="item in raw.map(item => item.type)" :key="item" :value="item">{{item}}</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <echarts style="height: 400px;width: 100%;" v-if="options" :option="options"></echarts>
      </div>
      <div class="card">
        <div class="title">数据详情</div>
        <a-table :pagination="false" :data-source="data" :columns="columns">
          <template #expandedRowRender="record">
            <div style="background-color: white">
              <a-table :showHeader="false" :pagination="false" :data-source="getChildData(record)">
                <a-table-column width="300px" title="产品名称" data-index="product_name"></a-table-column>
                <a-table-column width="300px" title="总数" data-index="schedule_count"></a-table-column>
                <a-table-column width="300px" title="已完成" data-index="actual_count"></a-table-column>
                <a-table-column title="差额" data-index="diff"></a-table-column>
              </a-table>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import DetailHead from "@/view/project/report/detailHead.vue";
import Echarts from "@/components/echarts.vue";
import {fetch} from "@/utils/request.js";

export default {
  name: "productReport",
  components: {Echarts, DetailHead},
  props: {
    prjOid: String,
    currentCat: Object,
    phaseList: Array
  },
  data() {
    return {
      columns: [],
      data: [],
      options: false,
      raw: [],
      productTypes: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getChildData(row) {
      let find = this.raw.find(item => item.index === row.key)
      return (find?.data ?? []).map(item => {
        return {
          ...item,
          diff: item.schedule_count - item.actual_count
        }
      })
    },
    handleRaw() {
      const width = {
        "总数": 300,
        "已完成": 300,
        "差额": ""
      }
      if (this.currentCat.cat_code === '8') {
        const status = ["总数", "已完成", "差额"]
        this.columns = [
          {title:"类型",dataIndex:"类型", width: 300},
          ...status.map(t => ({title: t, dataIndex: t, width: width[t]}))
        ]
        this.data = this.raw.map(item => {
          return {
            "类型": item.type,
            "总数": item.schedule,
            "已完成": item.actual,
            "差额": item.schedule - item.actual,
            key: item.index
          }
        })
        this.options = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: this.raw.map(item => item.type)
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '50px',
            bottom: '30px',
            right: "30px",
          },
          series: status.map(text => {
            return {
              type: "bar",
              barMaxWidth: "100px",
              name: text,
              stack: "1",
              data: this.data.map(item => {
                return Number(item[text]) || 0
              })
            }
          })
        }
      }
      if (this.currentCat.cat_code === '9') {
        const status = ["总数", "已完成", "差额"]
        this.columns = [
          {title:"供应商",dataIndex:"供应商", width: 300},
          ...status.map(t => ({title: t, dataIndex: t, width: width[t]}))
        ]
        this.data = this.raw.map(item => {
          return {
            "供应商": item.company_name,
            "总数": item.schedule,
            "已完成": item.actual,
            "差额": item.schedule - item.actual,
            key: item.index
          }
        })
        this.options = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: this.raw.map(item => item.company_name)
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '50px',
            bottom: '30px',
            right: "30px",
          },
          series: status.map(text => {
            return {
              type: "bar",
              barMaxWidth: "100px",
              name: text,
              stack: "1",
              data: this.data.map(item => {
                return Number(item[text]) || 0
              })
            }
          })
        }
      }
    },
    getData() {
      let map = {
        8: '3',// 产品类型分布
        9: '2' // 供应商 交付情况
      }
      fetch("post", "/prj/product/sta", {
        prjoid: this.prjOid,
        sta_type: map[this.currentCat.cat_code]
      }).then(res => {
        if (res.status === 0) {
          this.raw = res.data.filter(item => {
            if (this.productTypes.length) {
              return this.productTypes.includes(item.type)
            }
            return true
          }).map((item, i) => {
            return {
              ...item,
              index: i
            }
          })
          this.handleRaw()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content {
  /deep/ .ant-table-expanded-row {
    >td {
      padding: 0;
      .ant-table {
        margin-right: -1px;
        margin-bottom: -1px;
      }
      table {
        border-left: none;
      }
    }
  }
}
</style>