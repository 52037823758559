<template>
  <div>
    <detail-head :prj-oid="prjOid" :cat-name="currentCat.cat_name" :columns="columns" :data="data"></detail-head>
    <div class="content">
      <div class="card">
        <div class="title">
          图表
          <div class="right">
            <div class="item">
              <div class="label">阶段</div>
              <a-select @change="getData" v-model="phase_oid" style="width: 150px;">
                <a-select-option value="">全部阶段</a-select-option>
                <a-select-option v-for="item in phaseList" :key="item.oid" :value="item.oid">{{ item.phase_name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item" v-if="currentCat.cat_code === '7'">
              <div class="label">延误时间</div>
              <a-select @change="getData" v-model="delayDate" style="width: 150px;">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="item in delayDays" :key="item" :value="item">{{ item }}</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <echarts style="height: 400px;width: 100%;" v-if="options" :option="options"></echarts>
      </div>
      <div class="card">
        <div class="title">数据详情</div>
        <a-table :pagination="false" :data-source="data" :columns="columns">
          <template #expandedRowRender="record, key">
            <div style="background-color: white">
              <a-table v-if="currentCat.cat_code !== '7'" :showHeader="false" :pagination="false" :data-source="getChildData(record['0'])" rowKey="issue_name">
                <a-table-column width="500px" title="任务标题" data-index="issue_name"></a-table-column>
                <a-table-column width="300px" title="阶段名称" data-index="phase_name"></a-table-column>
                <a-table-column title="进度" data-index="complete_percent"></a-table-column>
                <a-table-column title="优先级" data-index="issue_level">
                  <template v-slot:default="_, row">
                    <div
                      v-if="row.issue_level"
                      class="edp-tag"
                      :class="{
                        green: row.issue_level === '100',
                        yellow: row.issue_level === '300',
                        blue: row.issue_level === '200',
                        red: row.issue_level === '400',
                      }"
                    >
                      {{ row.issue_level_name }}
                    </div>
                    <div v-else class="edp-tag">未设置</div>
                  </template>
                </a-table-column>
                <a-table-column title="状态">
                  <template v-slot:default="_, row">
                    <div
                      class="edp-status"
                      :class="{
                        blue: row.issue_status === '0',
                        yellow: row.issue_status === '1',
                        green: row.issue_status === '100',
                        gray: row.issue_status === '1000',
                      }"
                    >
                      {{ row.issue_status_name }}
                    </div>
                  </template>
                </a-table-column>
              </a-table>
              <a-table v-else :showHeader="false" :pagination="false" :data-source="getChildData(key)" rowKey="oid">
                <a-table-column title="任务负责人" data-index="issue_owner"></a-table-column>
                <a-table-column title="任务标题" data-index="issue_name" width="250px"></a-table-column>
                <a-table-column title="阶段名称" data-index="phase_name" width="250px"></a-table-column>
                <a-table-column title="进度" data-index="complete_percent" width="250px"></a-table-column>
                <a-table-column title="优先级" width="250px">
                  <template v-slot:default="_, row">
                    <div
                      v-if="row.issue_level"
                      class="edp-tag"
                      :class="{
                        green: row.issue_level === '100',
                        yellow: row.issue_level === '300',
                        blue: row.issue_level === '200',
                        red: row.issue_level === '400',
                      }"
                    >
                      {{ row.issue_level_name }}
                    </div>
                    <div v-else class="edp-tag">未设置</div>
                  </template>
                </a-table-column>
                <a-table-column title="状态" data-index="issue_type" width="250px">
                  <template v-slot:default="_, row">
                    <div
                      class="edp-status"
                      :class="{
                        blue: row.issue_type === '0',
                        yellow: row.issue_type === '1',
                        green: row.issue_type === '100',
                        gray: row.issue_type === '1000',
                      }"
                    >
                      {{ row.issue_type_name }}
                    </div>
                  </template>
                </a-table-column>
              </a-table>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import DetailHead from "@/view/project/report/detailHead.vue";
import Echarts from "@/components/echarts.vue";
import {fetch} from "@/utils/request.js";

export default {
  props: {
    currentCat: Object,
    prjOid: String,
    phaseList: Array
  },
  data() {
    return {
      raw: [],
      data: [],
      columns: [],
      phase_oid: "",
      delayDate: "",
      options: false,
      delayDays: ['1-3天', '3-7天', '7-15天', '15-30天', '>30天'],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getChildData(name) {
      if (this.currentCat.cat_code !== '7') {
        let find = this.raw.find(item => item.key === name)
        return (find?.value?.[0]?.data ?? []).map(item => {
          return {
            ...item,
            complete_percent: item.complete_percent + "%",
            issue_level: String(item.issue_level),
            issue_status: String(item.issue_status)
          }
        })
      } else {
        let values = {
          "0": [],
          "1": [0, 3],
          "2": [3, 7],
          "3": [7, 15],
          "4": [15, 30],
          "5": [30],
        }[name]
        return this.raw.filter(item => {
          if (values.length === 0) return true
          if (values.length === 1) return item.delay_days > values[0]
          if (values.length === 2) return item.delay_days > values[0] && item.delay_days <= values[1]
        }).map(item => {
          return {
            ...item,
            complete_percent: item.complete_percent + "%",
            issue_level: String(item.issue_level),
            issue_type: String(item.issue_type)
          }
        })
      }
    },
    handleRaw() {
      if (['1', '2'].includes(this.currentCat.cat_code)) {
        this.columns = [
          {title: "人员", dataIndex: "0", width: 800},
          ...this.raw[0].value.map((item) => {
            return {
              title: item.value_name,
              dataIndex: item.value_name
            }
          })
        ]
        this.data = this.raw.map(item => {
          let res = {
            0: item.key,
            key: item.key,
          }
          item.value.forEach(item => {
            res[item.value_name] = item.value
          })
          return res
        })
        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: this.raw.map(item => {
              return item.key
            })
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '30px',
            bottom: '30px',
            right: "30px",
          },
          series: {
            type: "bar",
            barMaxWidth: "100px",
            name: this.raw[0].value[0].value_name,
            data: this.raw.map(item => Number(item.value[0].value))
          }
        }
      }
      if (['3', '6'].includes(this.currentCat.cat_code)) {
        this.columns = [
          {title: "人员", dataIndex: "0"},
          {title: "数量", dataIndex: "1"},
        ]
        this.data = this.raw.map(item => {
          return {
            0: item.key,
            1: item.value.reduce((pre, cur) => {
              return pre + Number(cur.value)
            }, 0),
            key: item.key
          }
        })

        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: this.raw.map(item => {
              return item.key
            })
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '30px',
            bottom: '30px',
            right: "30px",
          },
          series: {
            type: "bar",
            barMaxWidth: "100px",
            name: this.raw.map(() => '状态'),
            data: this.raw.map(item => {
              return item.value.reduce((pre, cur) => pre + Number(cur.value), 0)
            })
          }
        }
      }
      if (['4'].includes(this.currentCat.cat_code)) {
        let status = ['未开始', '进行中', '已完成', '已延期']
        this.columns = [
          {title: "状态名称", dataIndex: "0"},
          {title: "数量", dataIndex: "1"},
        ]
        this.data = status.map(text => {
          return {
            0: text,
            1: this.raw.find(item => item.value[0].value_name === text)?.value[0].value ?? 0,
            key: text
          }
        })

        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: status
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '30px',
            bottom: '30px',
            right: "30px",
          },
          series: {
            type: "bar",
            barMaxWidth: "100px",
            data: status.map(text => {
              return {
                value: Number(this.raw.find(item => item.value[0].value_name === text)?.value[0].value ?? 0),
                itemStyle: {
                  color: {'未开始': '#0088FF', '进行中': '#FCBD26', '已完成': '#17C665', '已延期': 'red'}[text]
                }
              }
            })
          }
        }
      }
      if (['5'].includes(this.currentCat.cat_code)) {
        let status = ['未开始', '进行中', '已完成', '已延期']
        this.columns = [
          {title: '用户', dataIndex: '0'},
          ...status.map(item => ({title: item, dataIndex: item}))
        ]
        this.data = this.raw.map(item => {
          let result = {
            0: item.key,
            key: item.key
          }
          status.forEach(text => {
            result[text] = item.value.find(item => item.value_name === text)?.value ?? 0
          })
          return result
        })
        this.options = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: this.raw.map(item => item.key)
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '50px',
            bottom: '30px',
            right: "30px",
          },
          series: status.map(text => {
            return {
              type: "bar",
              barMaxWidth: "100px",
              name: text,
              itemStyle: {
                color: {'未开始': '#0088FF', '进行中': '#FCBD26', '已完成': '#17C665', '已延期': 'red'}[text]
              },
              stack: "1",
              data: this.raw.map(item => {
                console.log(item)
                return item.value.find(valueItem => valueItem.value_name === text)?.value || 0
              })
            }
          })
        }
      }
      if (['7'].includes(this.currentCat.cat_code)) {
        let days = this.delayDays
        let status = ['未开始', '进行中', '已完成', '已延期']
        let data = [
          {days: "全部", "未开始": 0, "进行中": 0, "已完成": 0, "已延期": 0, key: 1},
          {days: "1-3天", "未开始": 0, "进行中": 0, "已完成": 0, "已延期": 0, key: 2},
          {days: "3-7天", "未开始": 0, "进行中": 0, "已完成": 0, "已延期": 0, key: 3},
          {days: "7-15天", "未开始": 0, "进行中": 0, "已完成": 0, "已延期": 0, key: 4},
          {days: "15-30天", "未开始": 0, "进行中": 0, "已完成": 0, "已延期": 0, key: 5},
          {days: ">30天", "未开始": 0, "进行中": 0, "已完成": 0, "已延期": 0, key: 6},
        ]
        this.columns = [
          {dataIndex: 'days', title: ''},
          {dataIndex: '未开始', title: '未开始', width: 250},
          {dataIndex: '进行中', title: '进行中', width: 250},
          {dataIndex: '已完成', title: '已完成', width: 250},
          {dataIndex: '已延期', title: '已延期', width: 250},
        ]
        for (let rawItem of this.raw) {
          if (rawItem.issue_type in data[0]) {
            data[0][rawItem.issue_type] += 1
          }
          let setIndex = -1
          if (rawItem.delay_days > 0 && rawItem.delay_days <= 3) {
            setIndex = 1
          } else if (rawItem.delay_days > 3 && rawItem.delay_days <= 7) {
            setIndex = 2
          } else if (rawItem.delay_days > 7 && rawItem.delay_days <= 15) {
            setIndex = 3
          } else if (rawItem.delay_days > 15 && rawItem.delay_days <= 30) {
            setIndex = 4
          } else if (rawItem.delay_days > 30) {
            setIndex = 5
          }
          if (rawItem.issue_type in data[setIndex]) {
            data[setIndex][rawItem.issue_type] += 1
          }
          this.data = data
        }

        this.options = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: days
          },
          yAxis: {
            type: 'value'
          },
          grid: { // 让图表占满容器
            left: "40px",
            top: '50px',
            bottom: '30px',
            right: "30px",
          },
          series: status.map(text => {
            return {
              type: "bar",
              barMaxWidth: "100px",
              name: text,
              itemStyle: {
                color: {'未开始': '#0088FF', '进行中': '#FCBD26', '已完成': '#17C665', '已延期': 'red'}[text]
              },
              stack: "1",
              data: days.map(daysText => {
                return data.find(i => text in i && i.days === daysText)?.[text] || 0
              })
            }
          })
        }
      }
    },
    getData() {
      if (this.currentCat.cat_code === '7') {
        fetch("post", "/prj/issue/delay", {
          prjoid: this.prjOid,
          phase_oid: this.phase_oid
        }).then(res => {
          if (res.status === 0) {
            this.raw = res.data.filter(item => {
              if (this.delayDate === '') {
                return true
              } else {
                // delayDays: ['1-3天', '3-7天', '7-15天', '15-30天', '>30天'],
                switch (this.delayDate) {
                  case "1-3天":
                    return item.delay_days > 0 && item.delay_days <= 3
                  case "3-7天":
                    return item.delay_days > 3 && item.delay_days <= 7
                  case "7-15天":
                    return item.delay_days > 7 && item.delay_days <= 15
                  case "15-30天":
                    return item.delay_days > 15 && item.delay_days <= 30
                  case ">30天":
                    return item.delay_days > 30
                }
              }
            })
            this.handleRaw()
          }
        })
        return
      }
      // object key:cat_code value: 接口需要的report_flag
      let map = {
        1: "1",// 创建者分布
        2: "2",// 责任人分布
        3: "6",// 状态-参与者分布
        4: "3",// 状态分布
        5: "4",// 状态-责任人分布
        6: "7" // 外协参与状态分布
      }
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/report" : "/prj/issue/report"
      fetch("post", url, {
        prjoid: this.prjOid,
        report_flag: map[this.currentCat.cat_code],
        phase_oid: this.phase_oid
      }).then(res => {
        if (res.status === 0 || window.mock) {
          this.raw = res.data.datas
          this.handleRaw()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  },
  components: {Echarts, DetailHead},
}
</script>

<style scoped lang="less">
.content {
  /deep/ .ant-table-expanded-row {
    >td {
      padding: 0;
      .ant-table {
        margin-right: -1px;
        margin-bottom: -1px;
      }
      table {
        border-left: none;
      }
    }
  }
}
</style>