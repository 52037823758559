<template>
  <div class="header">
    <div class="left">
      <div class="bread">
        <router-link :to="{path: '/project/report', query: {prj_oid: prjOid}}" class="link">
          {{ headTitle }}
        </router-link>
        <a-icon type="right" />
        <span>报表详情</span>
      </div>
      <div class="title">{{ catName }}</div>
    </div>
    <div class="right">
      <slot name="right">
        <a-button @click="exportExcel">导出报表</a-button>
      </slot>
    </div>
  </div>
</template>

<script>
import * as xlsx from "xlsx";

export default {
  name: "detailHead",
  props: {
    prjOid: {
      type: String,
    },
    catName: {
      type: String
    },
    columns: {
      type: Array,
    },
    data: {
      type: Array
    }
  },
  computed: {
    headTitle() {
      return {'': '报表列表', '001': '任务报表', '002': '产品报表', '003': '财务报表', '004': '工时报表'}[this.$route.query.active_tab]
    }
  },
  methods: {
    exportExcel() {
      let data = []
      this.data.forEach(item => {
        let result = {}
        for (let column of this.columns) {
          if (column.dataIndex in item) {
            result[column.title] = item[column.dataIndex]
          }
        }
        data.push(result)
      })
      console.log(data+ 'data数据');
      const jsonWorkSheet = xlsx.utils.json_to_sheet(data);
      const workBook = {
        SheetNames: ['sheet1'],
        Sheets: {
          ['sheet1']: jsonWorkSheet,
        }
      };
      return xlsx.writeFile(workBook, 'data.xlsx');
    },
  }
}
</script>

<style scoped lang="less">
.header {
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex-shrink: 0;
  margin-bottom: 20px;
  >.left {
    color: #4D4D4D;
    > .bread {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 1px;
      .link {
        color: #08f;
      }
    }
    .title {
      font-size:20px;
      font-weight: 500;
    }
  }
  >.right {
    margin-left: auto;
  }
}
</style>